input,select,textarea{  }
input[type="text"],textarea,select,input[type="password"]{ -webkit-appearance: none; -moz-appearance: none; appearance: none; }
.bg-secondary input[type="text"], .bg-secondary textarea, .bg-secondary select{ background: #fff; }
.input-lh{ line-height: 50px; }
.field-error{ outline: 1px red !important; }
.input-with-label span{ font-size: 11px; text-transform: uppercase; letter-spacing: 1px; font-weight: 500; display: block; cursor: default; }
.image-bg input[type="text"], .image-bg textarea { color: #555; }
.image-bg.bg-light input.transparent{ border-color: $bg-blue; }
input.transparent{ background: none; border: 1px solid rgba(255,255,255,.5); color: #fff !important	; }
input[type="text"]:focus,input[type="password"]:focus,input[type="email"]:focus{ outline: 1px solid #ccc; }
textarea{ width: 100%; border: none; background: #f5f5f5; margin-bottom: 24px; border-radius: 0; padding: 16px 20px; }
textarea:focus{ outline: 1px solid #ccc; }

input.transparent::-webkit-input-placeholder { color: #fff; }
input.transparent::-moz-input-placeholder { color: #fff; }
input.transparent:-moz-input-placeholder { color: #fff; }
input.transparent:-ms-input-placeholder { color: #fff; }

input[type="submit"]:focus { outline: none; }
input[type="submit"].hollow { background: none; border: 2px solid $color-primary; color: $color-primary; @include transition(0.3s); }
input[type="submit"].hollow:hover { background: $color-primary; color: #fff; }

.select-option{ position: relative; cursor: pointer; height: 50px; overflow: hidden; margin-bottom: 24px; }
.select-option i{ position: absolute; font-size: 18px; right: 20px; top: 14px; z-index: 2; pointer-events: none; @include transition(0.3s); cursor: pointer; }
.select-option:focus i{ color: #fff; }
.select-option select{ margin-bottom: 0; }

select{ height: 50px; background: #f5f5f5; width: 100%; border-radius: 0; border: none; outline: none; padding-left: 20px; position: relative; font-size: 11px; text-transform: uppercase; font-weight: bold; letter-spacing: 1px; color: #777; @include transition(0.3s); cursor: pointer; }
select:focus{ background: #eee; }
select:active, select:focus, select:hover{ outline: none; border: none; }

.checkbox-option, .radio-option{ display: inline-block; width: 50px; height: 25px; border-radius: 25px; border: 1px solid $color-primary; cursor: pointer; @extend .disable-select !optional; margin-bottom: 24px; }
.checkbox-option .inner, .radio-option .inner{ width: 19px; height: 19px; border-radius: 50%; background: rgba(0,0,0,0); border: 1px solid $color-primary; position: relative; top: 2px; left: 2px; display: inline-block; @include transition(0.2s); }
.checkbox-option.checked .inner{ @include translateX(25px); background: $color-primary; }
.checkbox-option input{ width: 0; height: 0; opacity: 0; overflow: hidden; }

.radio-option{ width: 25px; height: 25px; text-align: left; }
.radio-option:nth-of-type(n+2){ margin-left: 24px; }
.radio-option input{ width: 0; height: 0; opacity: 0; overflow: hidden; }
.radio-option .inner{ border: none; width: 19px; height: 19px; left: 2px; @include scale(0); }
.radio-option + span{ display: inline-block; line-height: 25px; }
.radio-option.checked .inner{ @include scale(1); }
.radio-option.checked .inner{ background: $color-primary; }

@media all and (min-width: 991px){
	input.col-md-6{ width: 49%; float: left; }
	input.col-md-6:first-of-type{ margin-right: 1%; }
	input.col-md-6:last-of-type{ margin-left: 1%; }

}

form.thirds input{ width: 33%; }
form.halves input{ width: 49.5%; }

.form-error, .form-success{ background: #58ce38; padding: 12px; width: 100%; color: #fff; max-width: 700px; position: relative; top: 16px; margin: 0 auto; }
.form-error{ background: #ce3838; }
.field-error{ outline: 1px solid #ce3838 !important; }

form iframe.mail-list-form{ display: none;}

.form-loading{ border:3px solid rgba(255,255,255,1); border-radius:30px; height:30px; left:50%; margin:-15px 0 0 -15px; opacity:0; margin:0px auto; top:50%; width:30px; -webkit-animation: pulsate 1s ease-out; -webkit-animation-iteration-count:infinite; -moz-animation: pulsate 1s ease-out; -moz-animation-iteration-count:infinite; animation: pulsate 1s ease-out; animation-iteration-count:infinite; z-index: 99999;}
@keyframes pulsate { 0% { transform:scale(.1); opacity: 0.0; } 50% { opacity:1; } 100% { transform:scale(1.2); opacity:0; } }
@-webkit-keyframes pulsate { 0% { transform:scale(.1); opacity: 0.0; } 50% { opacity:1; } 100% { transform:scale(1.2); opacity:0; } }


@media all and (max-width: $screen-xs-max){
	form.thirds input, form.halves input{ width: 100%; margin-bottom: 8px; }
}

.contact {

	.MessageContainer,
	.errorMessageContainer {
		padding-bottom: 25px;
	}

	.MessageContainer { color: $turq; }
	.errorMessageContainer { 
		color: red;
		font-family: $heading-font;
		.errorMessage { padding-top: 15px; padding-top: 15px; font-family: $content-font; }
	}


	input,
	textarea {
		color: $white;
		border: solid 1px white;
		border-radius: 0;
		display: block;
		background: transparent;
		margin-bottom: 12px;
		width: 100%;
		font-size: 14px;
	}
	input {
		height: 35px;
		line-height: 35px;
		padding-left: 8px;
		padding-right: 8px;
	}

	// input[type="submit"] {
	// 	background: $blue;
	// 	color: $white;
	// 	border: none;
	// 	text-align: center;
	// 	width: 100%;
	// 	font-family: $link-font;
	// }
	textarea {
		line-height: 1.5;
		padding: 8px;
		height: 100px;
		@media (min-width: $screen-sm-min) { height: 120px; }
		@media (min-width: $screen-md-min) { height: 150px; }
		@media (min-width: $screen-xl-min) { height: 200px; }
	}

	a.link-line {
		display: block;
		text-align: center;
		&:after {
			margin: 15px auto 0;
			background-color: $white;
		}
	}
	input[type="checkbox"] {
		float: left;
		width: 20px;
		margin: 2px 5px 0 0;
		height: 10px;
		@media (max-width: $screen-xs-max) { margin: 5px 2px 0 -2px; height: 10px; }
		@media (min-width: $screen-sm-min) { margin: 5px 5px 0 0; height: 10px; }
		@media (min-width: $screen-md-min) { margin: 2px 5px 0 0; height: 15px; }
		@media (min-width: $screen-xl-min) { margin: 6px 5px 0 0; height: 15px; }
	}
	label {
		margin:0;
		font-weight: 500;
	}
}

::-webkit-input-placeholder { color: $white; opacity: 1; font-style: italic; }
:-moz-placeholder { color: $white; opacity: 1; font-style: italic; }
::-moz-placeholder { color: $white; opacity: 1; font-style: italic; }
:-ms-input-placeholder { color: $white; opacity: 1; font-style: italic; }