.textseite {

	&.width-icon {
		overflow: visible;
		padding-top: 40px;
		padding-bottom: 40px;
		@media(min-width: $screen-sm-min) { padding-top: 70px; padding-bottom: 50px; }
		@media(min-width: $screen-md-min) { padding-top: 90px; padding-bottom: 70px; }
		@media(min-width: $screen-xl-min) { padding-top: 100px; padding-bottom: 80px; }
		@media(min-width: $screen-lg-min) { padding-top: 120px; padding-bottom: 100px; }

		.text-bg-icon {
			display: block;
			left: 50%;
			position: absolute;
			height: 60px;
			width: 53px;
			margin-left: -27px;
			top: -30px;

			@media(min-width: $screen-md-min) { height: 80px; width: 73px; margin-left: -37px; top: -38px; }
			@media(min-width: $screen-lg-min) { height: 104px; width: 90px; margin-left: -45px; top: -52px; }
		}

		&.aktuelles .text-bg-icon { 
			//background-image: url(/dist/img/aktuelles-bg.svg);
		}

		&.karriere .text-bg-icon { 
			//background-image: url(/dist/img/karriere-bg.svg);
		}		

		&.leistungsspektrum .text-bg-icon { 
			//background-image: url(/dist/img/leistungsspektrum-bg.svg);
		}

		&.partner .text-bg-icon { 
			//background-image: url(/dist/img/partner-bg.svg);
		}		

		&.referenzen .text-bg-icon { 
			//background-image: url(/dist/img/referenzen-bg.svg);
		}

		&.team .text-bg-icon { 
			//background-image: url(/dist/img/team-bg.svg);
		}
		
		&.unternehmen .text-bg-icon { 
			//background-image: url(/dist/img/unternehmen-bg.svg);
		}

	}

}

.overviewDetail {
	figure,
	.overviewInner {
		margin: 0 auto;
		max-width: 280px;
		width: 100%;
	}

	.overviewCol {
		display: inline-block;
		vertical-align: top;
		float: none;
		margin-right: -4px;
		margin-top: 25px;
		margin-bottom: 25px;
	}

	figure {
		position: relative;
		clear: both;

		@media (max-width: $screen-xs-max) { 
			width: 80%;
			margin: 0 auto;
		}
		
		img {
			margin-bottom: 35px;
			max-width: 100%;
		}

	}
}