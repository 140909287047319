#map {
	.module-search {
		.raute-wrap {
			z-index: 9;
		}

		.haendler-content {
			padding: 50px 0;
			.haendler-lg {
				display: none;
				@media (min-width: $screen-md-min) { display: block; }
			}
			.haendler-sm {
				display: none;
				@media (max-width: $screen-sm-max) { display: block; }
			}
		}
	}
}

.hideMobile {
	display: none;
}

#map.displayMobile {
	height: auto;
}

#map .map-content.displayMobile {
	margin-top: 0;
	height: auto;
}

#map .map-content.displayMobile a.search {
	display: none;
}

#map .map-content.displayMobile a.locate {
	display: none;
}

#map .map-content.displayMobile #map-box a.close {
	display: none;
}

#map .map-content.displayMobile #map-box {
	left: 0;
	margin-left: 0;
	width: 100%;
	margin-top: 0;
	position: relative;
}

#map {
	padding: 0;
	height: 630px;
	#gm {
		height: 100%;
		.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
			bottom: auto !important;
			top: 43px !important;
			left:  15px !important;
			right:  auto !important;
		}
	}
	.map-content {
		width: 100%;
		height: 100%;
		margin-top: -630px;

		a.search,
		a.locate {
			position: absolute;
			display: block;
			width: 28px;
			height: 28px;
			margin: 15px 0 0 25px;
			background-color: $blue;
			padding: 4px;
			&:before {
				color: #fff;
				font-size: 20px;
			}
		}
		a.locate {
			//background-position: center right;
			//margin-left: 90px;
		}
		#map-box {
			width: 100%;
			position: absolute;
			// left: 75%;
			// margin-top: 140px;
			// margin-left: -175px;
			*zoom: 1;
			background: $bg-blue;
			-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
			-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
			box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);


			@media (min-width: $screen-sm-min) { right:  15px; top:  15px; width: 350px; }
			@media (min-width: $screen-lg-min) { right: 140px; top: 140px; }

			a.close {
				display: block;
				width: 21px;
				height: 21px;
				position: absolute;
				top: 8px;
				right: 8px;
				background: transparent url('/resources/img/close.gif') no-repeat center center;
			}
			hr { margin: 0; }

			.content {
				color: #ffffff;
				padding: 15px;
				line-height: 19px;
				h2 {
					font-size: 24px;
					line-height: 28px;
				}
				#haendler {
					.haendler-lg {
						display: none;
						padding: 10px 0;
						@media (min-width: $screen-md-min) { display: block; }
					}
					.haendler-sm {
						display: none;
						padding: 10px 0;
						@media (max-width: $screen-sm-max) { display: block; }
					}
					.geo a {
						color: $blue;
					}
				}
				.mapWrapper {
					position: relative;
					padding: 10px 0;
					height: 40px;
					#haendler-country {
						width: 30px;
						padding: 0 5px;
						height: 30px;
						line-height: 30px;
						float: left;
						-moz-appearance: menulist-button;
						z-index: 2;
						position: absolute;
					}
					input.text {
						color: $color-primary;
						padding: 0 30px 0 40px;
						width: 300px;
						line-height: 30px;
						height: 30px;
						border: none;
						box-shadow: none;
						width: 100%;
						position: absolute;
						z-index: 1;
					}
					a.search {
						right: 0;
						margin: 0;
						height: 30px;
						width: 30px;
						background-color: $blue;
						position: absolute;
						z-index: 2;
						&:before { font-size: 22px; }
					}
				}
			}
		}
	}
}

