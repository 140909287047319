section.textseite.partner {
	padding-bottom: 0;
	@media (min-width: $screen-sm-min) { padding-bottom: 2px; }
	@media (min-width: $screen-md-min) { padding-bottom: 12px; }
	@media (min-width: $screen-xl-min) { padding-bottom: 15px; }
	@media (min-width: $screen-lg-min) { padding-bottom: 25px; }

	.partnerLogos {
		text-align: center;
		margin-top: 50px;

		.col-centered {
			display: inline-block;
			float: none;
			margin-right: -4px;
			padding-top: 25px;
			padding-bottom: 25px;

			a {
				display: block;
			}

			img {
				max-width: 100%;
				height: auto;
			}

			img:not(:hover) {
				filter: grayscale(100%);
				-webkit-filter: grayscale(100%);
				-moz-filter: grayscale(100%);
				-ms-filter: grayscale(100%);
				-o-filter: grayscale(100%);
				filter: url(/dist/img/desaturate.svg#greyscale);
				filter: gray;
				-webkit-filter: grayscale(1);
			}

		}

	}


}

