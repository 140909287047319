.teamDetail {
	.effect {
		margin-top: 50px;
		@media (min-width: $screen-sm-min) { margin-top: 90px; }
		@media (min-width: $screen-md-min) { margin-top: 110px; }
		@media (min-width: $screen-xl-min) { margin-top: 130px; }
		@media (min-width: $screen-lg-min) { margin-top: 150px; }
	}

	figure,
	.teamInner {
		margin: 0 auto;
		max-width: 280px;
		width: 100%;
	}

	.teamCol {
		display: inline-block;
		float: none;
		margin-right: -4px;
	}

	figure {
		position: relative;
		clear: both;

		@media (max-width: $screen-xs-max) { 
			width: 80%;
			margin: 0 auto;
			img { 
				max-width: 100%; 
			}
			.mask {
				background-size: 126%;
			}
		}
		
		img {
			margin-bottom: 35px;
		}

		.mask {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
		}

	}

	.teamInner {

	}

}