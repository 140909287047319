/*!---------- 2. MIXINS ----------*/

@mixin transition($time){
	transition: all $time ease;
	-webkit-transition: all $time ease;
	-moz-transition: all $time ease;
}

@mixin transition-property($property,$time){
	transition: $property $time ease;
	-webkit-transition: $property $time ease;
	-moz-transition: $property $time ease;
}

@mixin transition-property-delay($property,$time,$delay){
	transition: $property $time ease;
	-webkit-transition: $property $time ease;
	-moz-transition: $property $time ease;
	transition-delay: $delay;
	-webkit-transition: $delay;
}

@mixin translateX($amount){
	transform: translate($amount,0,0);
	-webkit-transform: translate($amount,0,0);
	-moz-transform: translate($amount,0,0);
}

@mixin translateY($amount){
	transform: translate(0,$amount,0);
	-webkit-transform: translate(0,$amount,0);
	-moz-transform: translate(0,$amount,0);
}

@mixin v-align-children{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	flex-direction: row;
	-webkit-flex-direction: row;
}

@mixin scale($amount){
	transform: scale($amount);
	-webkit-transform: scale($amount);
}

@mixin v-align-children-column{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	flex-direction: column;
	-webkit-flex-direction: column;
}

@mixin disable-select{
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

@media all and (max-width: $screen-sm-max){
	.v-align-children{ display: block !important; }
}

@mixin v-align-transform {
	position: relative;
	transform: translate(0,-50%,0);
	-webkit-transform: translate(0,-50%,0);
	top: 50%;
	z-index: 2;
}

@mixin align-bottom{ position: absolute; bottom: 0; margin: 0 auto; left: 0; right: 0; z-index: 2; }
@mixin align-top{ position: absolute; top: 0; margin: 0 auto; left: 0; right: 0; z-index: 2; }

@mixin overlay-params($opacity,$color){
	position: absolute;
	content: '';
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: $color;
	opacity: $opacity;
	z-index: 2;
}

@mixin heading-colors($color){
	h1,h2,h3,h4,h5,h6{ color: $color; }
}

@mixin text-colors($color){
	p,span,li{ color: $color; }
}
