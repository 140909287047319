header {
	max-width: 1920px;
}

header a { 
	font-size: 14px;

	//@media(min-width: $screen-md-min) { font-size: 12px; }
	//@media(min-width: $screen-xl-min) { font-size: 13px; }
	//@media(min-width: $screen-lg-min) { font-size: 14px; }
}

.nav-container{ -webkit-backface-visibility: hidden; max-width: 100%; }
nav { background: #fff; -webkit-backface-visibility: hidden; max-width: 100%; }
nav ul { margin-bottom: 0; }

.module{ display: inline-block; }
.module-group{ display: inline-block; }
.module.left,.module-group.left{ float: left; }
.module.right,.module-group.right{ float: right; }

nav .btn, .nav-bar .btn{ margin: 0; height: auto; }
.nav-utility{ height: 45px; line-height: 43px; border-bottom: 1px solid #ccc; overflow: hidden; }
.nav-utility i{ position: relative; top: 1px; }

.pilgram_logo {
	@media (max-width: $screen-xs-max) { display: none; }
}

.nav-bar, 
.nav-bar .module,
.nav-bar .module-group,
.nav-bar .menu > li > a {
	height: 62px; 
	max-height: 62px;
	line-height: 62px;
	@media (min-width: $screen-sm-min) { height: 68px; max-height: 68px; line-height: 68px; }
	@media (min-width: $screen-xl-min) { height: 77px; max-height: 77px; line-height: 77px; }
	@media (min-width: $screen-lg-min) { height: $nav-height; max-height: $nav-height; line-height: $nav-line-height; }
}

@media (max-width: $screen-sm-max) { 
	#menu {
		height: auto;
		max-height: 0;
		line-height: 0;
		position: absolute;
		z-index: 9;
		background-color: $white;

		.menu > li {
			line-height: 3;

			> a {
				height: auto;
				line-height: 3;
				padding: 0;
				display: block;
				font-size: 17px;
			}

			ul.subnav > li > a {
				font-size: 15px;
			}
		}

	}
	.nav-open #menu {
		max-height: 1000px;
		line-height: 1.4;
	}
}

@media (min-width: $screen-md-min) { 
	.nav-bar .menu > li > a { 
		display: inline-block; 
		&:after {
			background-color: $blue;
			content: '';
			display: block;
			height: 1px;
			margin: -28px auto 0;
			width: 0;
			transition: all .3s linear;
		}

		&:hover {
			&:after {
				width: 36px;
			}
		}
	}
}

.logo { 
	height: 38px;
	width: 148px;
	@media (min-width: $screen-md-min) { height: 41px; width: 158px; }
	@media (min-width: $screen-xl-min) { height: 43px; width: 168px; }
	@media (min-width: $screen-lg-min) { height: 48px; width: 188px; }

}

.logo-light{ display: none; }
nav.bg-dark .logo-light{ display: inline; }
nav.bg-dark .logo-dark{ display: none; }

.has-dropdown { padding-right: 18px; }

.has-dropdown {
	&:after { 
		position: absolute; 
		top: 0; 
		right: 0; 
		font-size: 11px; 
		content: $icon-navi-arrow; 
		font-family: 'icomoon' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		@include transition(0.5s);
		@media (min-width: $screen-md-min) { right: 5px; }
		@media (min-width: $screen-xl-min) { right: 15px; }
		@media (min-width: $screen-lg-min) { right: 20px; }
	}
}

.menu > li.has-dropdown {
	&:after {
		top: 1px; 
		font-size: 6px;
	}
	&:hover:after {
		transform: scaleY(-1);
	}
}

.menu { 
	width: 100%; 
	height: 62px;
	@media (min-width: $screen-md-min) { height: 68px; }
	@media (min-width: $screen-xl-min) { height: 77px; }
	@media (min-width: $screen-lg-min) { height: $nav-height; }
}

.menu.inline-block{ width: auto; }
.menu li a { color: $color-primary; @include transition(0.3s); max-width: 100%; white-space: normal; }
.menu li a:hover{ opacity: 1 !important; }
.menu > li { 
	@include transition(0.3s); 
	float: left; 
	position: relative;
	opacity: 1;
	@media (min-width: $screen-md-min) { padding-left: 20px; padding-right: 20px; }
	@media (min-width: $screen-xl-min) { padding-left: 35px; padding-right: 35px; }
	@media (min-width: $screen-lg-min) { padding-left: 40px; padding-right: 40px; }

}


.menu > li:last-child{ margin-right: 0; }
.menu > li:last-child > ul{ right: 0; }
.menu > li:last-child > ul ul{ left: auto; right: 100%; }

.menu > li ul { width: auto; padding: 0; background: $white; position: absolute; z-index: 99; opacity: 0; @include transition(0.3s); @include translateY(10px); visibility: hidden; margin-top: -1px; }
.menu > li > ul > li { position: relative; line-height: 24px; width: 100%; vertical-align: top; background-color: transparent; @include transition(0.3s); }
.menu > li > ul > li, .menu > li > ul > li a { white-space:nowrap; }
.menu > li > ul > li > ul { background-color: $bg-primary; }
.menu > li > ul > li i{ display: inline-block; margin-right: 2px; }

.menu > li > ul > li:hover {
	background-color: $bg-primary;
}

.menu > li > ul > .has-dropdown {
	&:after {
		font-family: 'icomoon' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		@include transition(0.3s);

		@media (min-width: $screen-md-min) { 
			content: $icon-arrow-right;
			top: 15px;
			right: 24px;

		}
		@media (max-width: $screen-sm-max) { 
			top: 1px;
   			font-size: 6px;
		}

	}
	@media (min-width: $screen-md-min) { 
		&:hover:after {
			right: 14px;
		}
	}
}



.menu > li > ul li a{  height: auto; padding: 0 24px; line-height: 45px; }

.menu > li > ul > li > ul{ left: 100%; top: 0; }

.menu > li:hover > ul{ opacity: 1; @include translateY(0px); visibility: visible; }
.menu > li > ul > li:hover > ul, .has-dropdown:hover .mega-menu ul{ opacity: 1; @include translateY(0px); visibility: visible; }

.mega-menu{ width: auto !important; white-space: nowrap; line-height: 24px; }
.mega-menu ul{ position: relative !important; left: auto !important; padding: 0 !important; }
.mega-menu > li{ width: 200px !important; overflow: hidden; display: inline-block; }
.mega-menu .title{ letter-spacing: 1px; color: #fff; display: inline-block; padding: 6px 24px; text-transform: uppercase; font-size: 11px; font-weight: 600; }

.make-right{ right: 0; }

.module.widget-handle{ padding: 0 24px; cursor: pointer; position: relative; @extend .disable-select !optional; margin: 0;  }
@media all and (max-width: 1100px){ .module.widget-handle{ padding: 0 16px; } }
.module.widget-handle i{ font-size: 20px; line-height: $nav-line-height; opacity: 0.5; @include transition(0.3s); }
.module.widget-handle:hover i, .module.active i{ opacity: 1; }
.widget-handle .function{ @extend .cast-shadow; cursor: default; width: 200px; background: $bg-dark; position: absolute; z-index: 99; opacity: 0; @include transition(0.3s); @include translateY(10px); visibility: hidden; margin-top: -2px; right: 0; }
.module.widget-handle:hover .function{ opacity: 1; @include translateY(0px); visibility: visible; }
.module.widget-handle .title{ font-family: $heading-font,Helvetica,Arial,sans-serif; letter-spacing: 1px; text-transform: uppercase; font-size: 11px; font-weight: 600; display: none; opacity: .5; @include transition(0.3s);  }
.module.widget-handle .title:hover{ opacity: 1; }

.widget-handle .cart{ position: relative; }
.widget-handle .cart .label{ width: 17px; height: 17px; font-size: 10px; line-height: 17px; padding: 0; text-align: center; position: absolute; background: $color-primary; top: 10px; right: -10px; border-radius: 50%;	 }

.widget-handle .search-form{ padding: 8px; display: inline-block; width: 100%; line-height: 50px; }
.widget-handle .search-form input{ margin: 0; font-size: 16px; }

nav .widget{ margin: 0; padding: 24px; }
nav .widget .title{ display: none !important; }
nav .widget, nav .widget a:not(.btn){ color: #fff; }
nav .widget hr{ border-color: #777; margin-bottom: 16px; }
nav .widget hr:first-of-type{ display: none; }
.cart-widget-handle .function{ width: auto; background: #fff; }
.cart-widget-handle .function span{ color: #222 !important; }
nav .cart-overview{ min-width: 300px; margin-bottom: 16px; }
nav .cart-overview a{ height: auto; }

.language .menu > li ul{ max-width: 150px; }

.nav-open{ max-height: 10000px !important; height: auto !important; }
.nav-open .mobile-toggle{ border-bottom: 1px solid #ccc; }

@media all and (max-width: 1023px){
 	.menu > li { margin-right: 45px; }
}

.has-offscreen-nav .main-container{ @include transition(0.4s); }

.offscreen-container{ position: fixed; @include translateX(200%); width: 50%; top: 0; height: 100%; min-height: 100vh; z-index: 20; @include transition(0.4s); overflow: hidden; }
.offscreen-container.reveal-nav{ @include translateX(100%); }
.main-container.reveal-nav{ @include translateX(-50%); }

.offscreen-left .offscreen-container{ @include translateX(-50%); }
.offscreen-left .offscreen-container.reveal-nav{ @include translateX(0%); }
.offscreen-left .main-container.reveal-nav, .offscreen-left nav.reveal-nav{ @include translateX(50%); }


.offscreen-container .close-nav{ position: absolute; right: 24px; top: 16px; z-index: 24; font-size: 20px; @include transition(0.3s); opacity: .5; }
.offscreen-container .close-nav:hover{ opacity: 1; }
.offscreen-container.bg-dark .close-nav i{ color: #fff; }

@media all and (max-width: $screen-sm-max){
	.offscreen-container{ width: 100vw; }
	.offscreen-container.reveal-nav{ @include translateX(0vw); }
	.main-container.reveal-nav{ transform: none !important; }
}

@media all and (max-width: $screen-sm-max){

	nav.fixed{ position: absolute !important; opacity: 1 !important; visibility: visible !important; }
	nav.outOfSight{ @include translateY(0px); @include transition(0.3s); }

	.nav-bar, .nav-bar .module-group, .nav-bar .module{ /*height: auto;*/ overflow: hidden; }
	.nav-bar .module{ padding: 0 16px; }
	.nav-bar .module-group{ width: 100%; padding: 16px 0; }
	.nav-bar .module-group .module{ display: block; float: none; width: 100%; }

	.menu{ height: auto;  }
	.menu.inline-block{ width: 100%; }
	.menu a { height: auto; line-height: 24px; padding: 4px 0; }
	.menu li { line-height: 24px; float: none; display: block; width: 100%; max-width: 100%; }
	.menu > li ul{ position: relative; width: 100%; opacity: 1; visibility: visible; @include translateY(0px); left: 0; }
	.menu > li > ul{ position: relative; opacity: 1; visibility: visible; display: none; @include translateY(0px); }
	
	// .menu > li > ul > .has-dropdown:after{ content: "\e64b"; }
	
	.menu > li > ul > li > ul{ left: 0; display: none; padding: 0; }
	.menu > li > ul li a, .mega-menu .title{ padding: 4px 16px; }
	.has-dropdown .has-dropdown li{ padding-left: 18px; }
	.has-dropdown{ padding-right: 0; }
	.mega-menu{ margin-left: 0 !important; }
	.mega-menu li{ width: 100% !important; }
	.toggle-sub > ul,.toggle-sub .mega-menu ul{ display: block !important; }

	//.module.widget-handle { border-left: none; border-top: 1px solid #ccc; line-height: 40px; min-height: 40px; }
	.module.widget-handle .menu{ line-height: 40px; }
	.module.widget-handle .menu li{ line-height: 40px; }
	.module.widget-handle i{ line-height: 40px; }
	.module.widget-handle .title{ display: inline-block; position: relative; bottom: 3px; margin-left: 8px; }
	.widget-handle .function{ width: 100%; position: relative; opacity: 1; @include translateY(0px); visibility: visible; margin-top: 0; display: none; box-shadow: none !important; }
	.toggle-widget-handle .function{ display: block !important; }

	.widget-handle .cart .label{ width: 22px; height: 22px; font-size: 12px; line-height: 22px; top: 18px; }

	.mobile-toggle{ border-left: 1px solid #ccc !important; border-bottom: none !important; }
	.mobile-toggle i{ line-height: 53px !important; }
	.mobile-toggle.absolute-xs{ position: absolute; top: 0; }

	nav.bg-dark .module.widget-handle{ border-top: 1px solid #444; }
	nav.bg-dark .mobile-toggle{ border-left: 1px solid #444 !important; }
	nav.bg-dark .nav-open .mobile-toggle{ border-bottom: 1px solid #444; }

}

nav.outOfSight{ @include translateY(-200px); @include transition(0.3s); }
nav.scrolled{ @include translateY(0px); }
nav.fixed,nav.absolute{ top: 0; width: 100%; z-index: 999; left:0; right: 0; }
nav.absolute{ position: absolute; }
nav.fixed{ position: fixed; visibility: hidden; opacity: 0;  }
nav.fixed .nav-utility{ display: none; }
nav.fixed.scrolled{ visibility: visible; opacity: 1; }

nav.transparent{ background: none; }
nav.transparent .menu > li > a, nav.transparent .module.widget-handle i, nav.transparent .nav-utility{ color: #fff; }
nav.transparent .nav-utility, nav.transparent .nav-bar{ border-bottom: 1px solid rgba(255,255,255,0.2); }
nav.transparent .module.widget-handle{ border-left: 1px solid rgba(255,255,255,0.2) !important; }
nav.transparent .menu > li > a, nav.transparent .module.widget-handle i{ opacity: 1; }
nav.transparent .has-dropdown:after{ color: #fff; }
nav.transparent .logo-dark{ display: none; }
nav.transparent .logo-light{ display: inline; }

@media all and (max-width: $screen-sm-max){
	nav.transparent .nav-open{ background: #fff; }
	nav.transparent .nav-open .menu > li > a, nav.transparent .nav-open .module.widget-handle i, nav.transparent  .nav-open .nav-utility{ color: $bg-dark; }
	nav.transparent .nav-open .logo-dark{ display: inline; }
	nav.transparent .nav-open .logo-light{ display: none; }
	nav.transparent .nav-open .has-dropdown:after{ color: $bg-dark; }
	.menu > li:last-child > ul ul{ right: 0; }
}

nav.transparent.fixed{ background: #fff; }
nav.transparent.fixed .logo-light{ display: none; }
nav.transparent.fixed .logo-dark{ display: inline; }
nav.transparent.fixed .menu > li > a, nav.transparent.fixed .module.widget-handle i{ color: $bg-dark; }
nav.transparent.fixed .menu > .has-dropdown:after{ color: #fff; color: $bg-dark; }
nav.transparent.fixed .module.widget-handle{ border-left: 1px solid #ccc; }
nav.transparent.fixed .menu > li > a, nav.transparent.fixed .module.widget-handle i{ opacity: .5; }

nav.bg-dark .menu > li > a, nav.bg-dark .module.widget-handle i, nav.bg-dark .nav-utility{ color: #fff; opacity: .7; }
nav.bg-dark .module.widget-handle{ border-left: 1px solid rgba(255,255,255,0.2); }
nav.bg-dark .nav-utility, nav.bg-dark .nav-bar{ border-bottom: 1px solid rgba(255,255,255,0.2); }

nav.transparent.fixed.bg-dark{ background: $bg-dark; }
nav.transparent.fixed.bg-dark .menu > li > a,nav.transparent.fixed.bg-dark .module.widget-handle i{ color: #fff; }

.nav-is-overlay nav{ opacity: 1; visibility: visible; @include translateX(0); }

// header {
// 	position: relative;
// 	z-index: 2;
// }


header {
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 9999;
	transform: translateY(0px);
	transition: all .5s;

	&.hideHeader {
		transform: translateY(-62px);
		@media(min-width: $screen-sm-min) { transform: translateY(-68px); }
		@media(min-width: $screen-md-min) { transform: translateY(-77px); }
		@media(min-width: $screen-lg-min) { transform: translateY(-92px); }
	}

	.phone {
		@media(max-width: $screen-xs-max) {
			background-color: transparent !important;
			width: 62px;
			text-align: center;
			opacity: 0.7;
			border-right: 1px solid #777777;
			border-left: 1px solid #777777;
		}
		@media(max-width: 319px) {
			display: none;
		}
		a {
			line-height: 62px;
			@media(min-width: $screen-sm-min) { line-height: 68px; }
			@media(min-width: $screen-md-min) { line-height: 77px; }
			@media(min-width: $screen-lg-min) { line-height: 92px; }

			@media(max-width: $screen-xs-max) {
				text-align: center;
				font-size: 0;
				color: transparent;
				width: 100%;
				display: block;
				&:after {
					font-size: 22px;
					color: $blue;
					content: $icon-phone;
					font-family: 'icomoon' !important;
					speak: none;
					font-style: normal;
					font-weight: normal;
					font-variant: normal;
					text-transform: none;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
				}
			}
		}

	}

	.phone_ico {
		@media(min-width: $screen-sm-min) { 
			display: none;
			border-left: solid 1px #777777;
			border-right: solid 1px #777777;
		}
	}

	.mobile-toggle {
		width: 62px;
		line-height: 62px;
		text-align: center;
		font-size: 28px;
		transition: .4s;
  		transform-origin: center;
  		border: none !important;

		.icon-close { font-size: 22px; display: none;}

		&.toggle-widget-handle {
			transform: rotate(-180deg) /*translateY(10px)*/;
			.icon-close { display: inline-block;}
			.icon-menu { display: none;}
		}

		@media(min-width: $screen-sm-min) { width: 68px; line-height: 68px; }
	}

	@media(max-width: $screen-xs-max) {
		#menu .menu li.has-dropdown:after {
			right: -12px;
		}
	}

	@media(max-width: $screen-sm-max) {
		#menu .menu li {
			border-bottom: 1px solid $bg-primary;
			a { margin-right: 60px; }
			&.has-dropdown:after {
				// top: 14px;
				// right: 14px;
				top: 0;
				right: -8px;
				width: 52px;
				height: 52px;
				line-height: 52px;
				text-align: center;
			}
		}
		.nav-open .module-group.right {
			border-top: 1px solid $bg-primary;
			height: auto;
			max-height: 10000px;
			padding: 0;
		}
	}

}

.pilgram-ico {
	@media (max-width: 735px) {
		display: none;
	}
}


