$icon-font-path: "bootstrap-sass/assets/fonts/bootstrap/";

$heading-font	: 'Roboto';
$content-font	: 'Roboto';
$link-font		: 'Roboto';
$icon-font		: 'icomoon' !important;

$font-family-sans-serif:  $content-font !default;
$font-family-base:        $content-font !default;
$font-size-base:          16px !default;


$blue			: #2c9b3e;
$white			: #fff;

$grey-quote		: #d1d2d7;

//$bg-primary	 	: #e9e8e8;
$bg-primary	 	: #ffffff;
$bg-secondary 	: #fff;
$bg-blue		: #2c9b3e;
$bg-pilgram-grey: #eaeaea;
$color-primary	: #2c9b3e;
$color-secondary: #2c9b3e;
$bg-dark		: #20722d;


	$turq			: #3abfb1;
	$light			: #cacaca;

	$bg-light		: #cacaca;
	$bg-blue-light	: #7fc4e7;
	$bg-turq		: #304f8f;
	$bg-turq-light	: #d8f2ef;
	$table-blue		: #b2dbf1;



$nav-height		: 92px;
$nav-line-height: 92px;

$grid-gutter-width: 36px;

$screen-xs-max :  735px;
$screen-sm-max : 1023px;
$screen-md-max : 1279px;
$screen-xl-max : 1439px;

$screen-sm-min :  736px;
$screen-md-min : 1024px;
$screen-xl-min : 1280px;
$screen-lg-min : 1440px;

$screen-iphone-4-p  : 320px; // iPhone 4 und iPhone 5
$screen-iphone-4-l  : 480px; // iPhone 4
$screen-iphone-5-l  : 568px; // iPhone 5
$screen-iphone-6-p  : 375px; // iPhone 6
$screen-iphone-6-l  : 667px; // iPhone 6
$screen-iphone-6p-p : 414px; // iPhone 6plus
$screen-iphone-6p-l : 736px; // iPhone 6plus
$screen-samsung-p	: 360px; // Samsung Galaxy 3/4/5/6
$screen-samsung-l	: 640px; // Samsung Galaxy 3/4/5/6
$screen-nexus-5-p	: 411px; // Nexus 5X
$screen-nexus-5-l	: 731px; // Nexus 5X
$screen-nexus-6-p	: 435px; // Nexus 6P
$screen-nexus-6-l	: 773px; // Nexus 6P
