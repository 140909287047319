body { 
	-webkit-font-smoothing: antialiased; 
	-moz-osx-font-smoothing: grayscale; 

	color: $color-primary;
	background: $bg-primary;
    overflow-x: hidden;
}
body.boxed-layout{ background: #eee; }
body.boxed-layout .main-container{ background: #fff; }
body.boxed-layout, .boxed-layout .nav-container, .boxed-layout .main-container, .boxed-layout nav{ max-width: 1366px; margin: 0 auto; left: 0; right: 0; }
ul { list-style: none; }
ul.bullets{ list-style: inside; }
.main-container{ clear: both; }
hr{ border: none; border-top: 1px solid #ccc; margin: 0 0 24px 0; width: 100%; }
hr.short-thick{ max-width: 50px; border-top: 5px solid #ccc; opacity: 1 !important; }
.image-bg hr{ border-color: #fff; opacity: .6; }
.inline-block{ display: inline-block; }
.list-inline{ margin-left: 0; }
.list-inline>li{ padding: 0 8px; }
.list-inline>li:last-child{ padding-right: 0; }
.list-inline>li:first-child{ padding-left: 0; }
.overflow-hidden{ overflow: hidden; }
.display-block{ display: block; }
.show-grid{ border: 1px dashed rgba(255,255,255,0); padding: 8px; @include transition(0.2s); cursor: default; }
.show-grid:hover{ border-color: #222; }
.right{ right: 0; }
.relative{ position: relative; z-index: 2; }
.clearboth{ clear: both; }
.spread-children *{ display: inline-block; margin-left: 12px; margin-right: 12px; }
.spread-children-large *{ display: inline-block; margin-left: 24px; margin-right: 24px; }
.container{ position: relative; }
.vnu{ display: inline; }

.container-fluid {
    width: 100%;
    @media(min-width: $screen-sm-min) { 
        padding-left: 30px;
        padding-right: 30px;
    }
}

.container {
    max-width: 1280px;
    width: 100% !important;
}

.main-container {
    max-width: 1920px;
    margin-top: 62px;
    @media(min-width: $screen-sm-min) { margin-top: 68px; }
    @media(min-width: $screen-xl-min) { margin-top: 77px; }
    @media(min-width: $screen-lg-min) { margin-top: 92px; }
}

.p0 {
    padding: 0;
}

.v-align-transform {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

// .dark-banner {
//     text-align: center;
//     color: #fff;
//     background-color: #262626;
//     font-weight: 300;
//     clear: both;

//     a {
//         color: #f4f4f4;
//         font-weight: 100;
//         &:hover,
//         &:focus {
//             color: #f4f4f4;
//             text-decoration: none;
//         }
//     }

//     @media ($screen-lg-min) {
//         line-height: 240px;
//         font-size: 35px;
//     }
//     @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
//         line-height: 130px;
//         font-size: 32px;
//     }
//     @media (max-width: $screen-xs-max) {
//         line-height: 100px;
//         font-size: 22px;
//     }

// }

@media all and (max-width: 1100px){
	.col-md-push-1 { left: 0; }
}

@media all and (max-width: $screen-sm-min){
	.pull-left-sm{ float: left !important; }
	.overflow-hidden-xs{ overflow: hidden; }
}

@media all and (max-width: $screen-xs-max){
	.spread-children *{ margin-left: 6px; margin-right: 6px; }
}

br {
    display: block !important;
}

.esy_5_cols {
    width: 20%;
    float: left;
    position: relative;

    @media (max-width: $screen-iphone-4-p){
        width: 50%;
    }

    @media (min-width: 321px) {
        width: 33.33333333%;
    }

    @media (min-width: $screen-xs-max){
        width: 20%;
    }
}

@media (min-width: $screen-sm-min) {
    .v-align-wrap {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        height: 100%;
        -webkit-box-align: center; 
        -webkit-align-items: center; 
        -ms-flex-align: center; 
        align-items: center;

        .v-align-inner {
            margin-top: auto;
            margin-bottom: auto;
        }

    }
}