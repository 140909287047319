// .newsContent,
// .newsDashboard {

// 	.textseite {
// 		padding-bottom: 25px;
// 		@media (min-width: $screen-sm-min) { padding-bottom: 27px; }
// 		@media (min-width: $screen-md-min) { padding-bottom: 37px; }
// 		@media (min-width: $screen-xl-min) { padding-bottom: 40px; }
// 		@media (min-width: $screen-lg-min) { padding-bottom: 50px; }
// 	}

// 	.news-teaser {

// 		.news-img,
// 		.news-text {
// 			float: left;
// 			overflow: hidden;
// 			width: 100%;
// 		}

// 		.news-text .time {
// 			font-size: 18px;
// 			margin-top: 40px;
// 			margin-bottom: 15px;
// 		}

// 	}

// 	time {
// 		display: block;
// 		font-size: 18px;
// 		margin-top: -6px;
// 		margin-bottom: 15px;
// 	}

// 	.moreNewsBtn {
// 		text-align: center;
// 		margin-top: 25px;
// 		@media (min-width: $screen-sm-min) { margin-top: 27px; }
// 		@media (min-width: $screen-md-min) { margin-top: 37px; }
// 		@media (min-width: $screen-xl-min) { margin-top: 40px; }
// 		@media (min-width: $screen-lg-min) { margin-top: 50px; }
// 	}

// 	.newsDetail {
// 		margin-bottom: 25px;
// 		@media (min-width: $screen-sm-min) { margin-bottom: 27px; }
// 		@media (min-width: $screen-md-min) { margin-bottom: 37px; }
// 		@media (min-width: $screen-xl-min) { margin-bottom: 40px; }
// 		@media (min-width: $screen-lg-min) { margin-bottom: 50px; }

// 		&:last-of-type {
// 			margin-bottom: 0;
// 		}
// 	}

// }

.timeline-left,	
.timeline-right {
	display: none;
}

@media(min-width:$screen-sm-min) {	

	.timeline-left,
	.timeline-right {
		position: absolute;
		display: block;
		top: 0;
		height: 17px;
	}

	.timeline-left {
		width: 15px;
		right: -8px;
		background: url(/dist/img/timeline-bg.svg) no-repeat;
	}
	.timeline-right {
		width: 15px;
		left: -7px;
		background: url(/dist/img/timeline-bg.svg) no-repeat;
	}

}


.timeline-container {
	margin: 35px auto 0;
	max-width: 914px;

	.p35 {
		padding-left: 35px;
		padding-right: 35px;
	}

	@media(min-width:$screen-sm-min) {		
		.m45 {
			margin-top: 45px;
		}
	}

	.clearLeft {
		clear: left;
	}

	.timline-bg {
		margin-left: -35px;
		margin-right: -35px;

		@media(min-width:$screen-sm-min) {		
			background: url(/dist/img/timeline-bg.gif) repeat-y center center;
		}


		.clearAfter {

			&:after,
			&:before { 
				display: table;
			}
			&:after {
				content: "";
				clear: both;
			}

		}

		.timeline-month {
			font-size: 20px;
			line-height: 32px;
			text-align: center;
			margin-bottom: 35px;
			font-family: $link-font;

			span {
				padding: 15px 50px;
				color: $blue;
				background: $bg-primary;
			}

		}

		.timeline-detail {
			@media(min-width: $screen-sm-min) { 
				border: 1px solid $blue;
			}
			margin-bottom: 35px;
			position: relative;
			color: $color-primary;

			

			.timeline-slider-big img {
				width: 100%;
				height: auto;
			}

			.timeline-headline {
				font-size: 16px;
				line-height: 1.375;
				margin-top: 15px;
				margin-bottom: 25px;
				display: block;
	
				@media(min-width: $screen-md-min) { font-size: 1.757812vw; }
				@media(min-width: $screen-xl-min) { font-size: 1.40625vw; line-height: 1.333333; }
				@media(min-width: $screen-lg-min) { font-size: 1.041666vw; line-height: 1.25; }
			}

			.timeline-headline,
			.timeline-content {
				padding-left: 25px;
				padding-right: 25px;
			}

			.timeline-content {
				&, & p {
					font-size: 13px;
					line-height: 1.538461;
					
					@media(min-width: $screen-md-min) { font-size: 14px; line-height: 1.571428; }

				}
				a.more {
					display: block;
					font-family: $link-font;
					font-size: 15px;
					height: 29px;

					&:after {
						content: ' »';
						margin-left: 0;
						@include transition(0.3s);
					}
					&:hover:after {
						margin-left: 10px;
					}
				}
			}

			.timeline-wrap {
				margin-top: 25px;
				position: relative;
				overflow: hidden;

				.btn {
					padding: 0 25px;
					line-height: 32px;
					height: 32px;
					min-width: 100px;
					border-radius: 0;
					background: $blue;
					font-family: $link-font;
					font-size: 17px;
					cursor: auto;
				}

				.timeline-more {
					float: left;
				}

				.timeline-date {
					float: right;
				}

			}

		}

	}

}