@font-face {
	font-family: 'icomoon';
	src:  url('/dist/fonts/icons/icomoon.eot?fc4iep');
	src:  url('/dist/fonts/icons/icomoon.eot?fc4iep#iefix') format('embedded-opentype'), url('/dist/fonts/icons/icomoon.ttf?fc4iep') format('truetype'), url('/dist/fonts/icons/icomoon.woff?fc4iep') format('woff'), url('/dist/fonts/icons/icomoon.svg?fc4iep#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

$icon-navi-arrow: '\e900';
$icon-arrow-left: '\e901';
$icon-arrow-right: '\e902';

// $icon-team: '\e903';
// $icon-aktuelles: '\e904';
// $icon-karriere: '\e905';
// $icon-leistungsspektrum: '\e906';
// $icon-partner: '\e907';
// $icon-referenzen: '\e908';
// $icon-unternehmen: '\e909';

$icon-phone: '\e90a';
$icon-close: '\e90b';
$icon-menu: '\e90c';


$icon-eickelbaum_icons_markisen: '\e90d';
$icon-eickelbaum_icons_sonnenschutz: '\e90e';
$icon-eickelbaum_icons_rollaeden: '\e90f';
$icon-eickelbaum_icons_smart_home: '\e910';
$icon-eickelbaum_icons_tore: '\e911';

.icon-phone:before {
	content: "\e90a";
}
.icon-close:before {
	content: "\e90b";
}
.icon-menu:before {
	content: "\e90c";
}
.icon-navi-arrow:before {
	content: "\e900";
}
.icon-arrow-left:before {
	content: "\e901";
}
.icon-arrow-right:before {
	content: "\e902";
}

.icon-eickelbaum_icons_markisen:before {
	content: "\e90d";
  }
  .icon-eickelbaum_icons_sonnenschutz:before {
	content: "\e90e";
  }
  .icon-eickelbaum_icons_rollaeden:before {
	content: "\e90f";
  }
  .icon-eickelbaum_icons_smart_home:before {
	content: "\e910";
  }
  .icon-eickelbaum_icons_tore:before {
	content: "\e911";
  }