/*!---------- 6. BUTTONS ----------*/

.btn { border: 2px solid $color-primary; padding: 0 26px; height: 40px; min-width: 150px; line-height: 36px; font-size: 12px; font-weight: 700; text-transform: uppercase; letter-spacing: 1px; border-radius: 0; color: $color-primary; text-align: center; @include transition(0.3s); margin-right: 8px; margin-bottom: 24px; }
.btn:last-child, .btn:last-of-type{ margin-right: 0; }
.btn:hover{ background: $color-primary; color: #fff; }
.btn-icon{ width: 40px; height: 40px; font-size: 20px; min-width: 0; padding: 0; line-height: 38px; }
.btn-lg{ height: 50px; line-height: 46px; min-width: 200px; }
.btn-icon.btn-lg{ width: 50px; height: 50px; line-height: 49px; font-size: 24px; min-width: 0; }
.btn-icon.btn-sm{ width: 30px; height: 30px; line-height: 29px; font-size: 13px; min-width: 0; padding: 0 0 0 1px!important; }
.btn-sm{ height: 30px; font-size: 11px; line-height: 27px; min-width: 0; }
.btn-filled{ background: $color-primary; color: #fff; }
.btn-white,.image-bg .btn, .image-bg .btn:visited{ color: #fff; border-color: #fff; }
.btn-white:hover, .image-bg .btn:hover, .image-bg .btn:visited:hover{ background: #fff; color: #222; }
.image-bg .btn.btn-filled, .image-bg .btn-filled:visited{ border-color: $color-primary; }
.image-bg .btn-filled:hover{ border-color: #fff; }
.btn-rounded{ border-radius: 25px; }
body.btn-rounded .btn{ border-radius: 25px !important; }

.btn:visited{ color: $color-primary; }
.btn-white:visited,.btn:visited:hover{ color: #fff; }
.btn-white:visited:hover{ color: #222; }
.btn-filled:visited{ color: #fff; }
.btn.bg-dark { color: #fff; border-color: $bg-blue; }
.btn.bg-dark:hover { background: lighten($bg-blue,10%); }
.btn:active, .btn.active { box-shadow: none; }

.linkBtn,
.btn,
.downloads .downloadText .downloadButtons a {
	border: none;
	background-color: $blue;
	color: #fff !important;
	display: inline-block;
	font-weight: 300;	
	font-size: 14px;
	line-height: 40px;
	height: 40px;
	margin: 0;
	padding: 0 15px;
	text-transform: none;

	@media (min-width: $screen-sm-min) { 
		padding: 0 25px;
		line-height: 50px;
		height: 50px;
	}
	&:hover,
	&:focus {
		background-color: $turq;
	}
}

.shop .bike-info .btn {
	@media (min-width: $screen-lg-min) { 
		padding: 0 50px;
		line-height: 70px;
		height: 70px;
		font-size: 18px;
	}
}

.textseite .linkBtn {
	margin-bottom: 5px;
}