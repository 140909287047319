/* Slider */
.slick-slider {position: relative;display: block;-moz-box-sizing: border-box;box-sizing: border-box;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;-ms-touch-action: pan-y;touch-action: pan-y;-webkit-tap-highlight-color: transparent; width: 100%; }
.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; &:focus { outline: none; } &.dragging { cursor: pointer; cursor: hand; } }
.slick-slider .slick-track, .slick-slider .slick-list {z-index: 0; -webkit-transform: translate(0, 0, 0);-moz-transform: translate(0, 0, 0);-ms-transform: translate(0, 0, 0);-o-transform: translate(0, 0, 0);transform: translate(0, 0, 0);}
.slick-track { position: relative; left: 0; top: 0; display: block; &:before, &:after { content: ""; display: table; } &:after { clear: both; } .slick-loading & { visibility: hidden; } }
.slick-slide { float: left; height: 100%; min-height: 1px; [dir="rtl"] & { float: right; } img { display: block; } &.slick-loading img { display: none; } display: none; &.dragging img { pointer-events: none; } .slick-initialized & { display: block; } .slick-loading & { visibility: hidden; } .slick-vertical & { display: block; height: auto; border: 1px solid transparent; } &:focus { outline: none; } }
.slick-arrow.slick-hidden { display: none; }


.slick-slide img { width: 100%; height: auto; }

.nextwrap,
.prevwrap {
	position: absolute;
	top: 50%;
	margin-top: -28px;
	height: 56px;
	z-index: 10;
	cursor: pointer;
	background-color: transparent;
	background-repeat: no-repeat;
	background-size: auto 100%;

	@media(max-width: $screen-xs-max){
		top: calc(263px / 2);
	}
}

.slick-prev, .slick-next {
	display: block;
	height: 100%;
	width: 24px;
	color: transparent;
	border: 0px none;
	font-size: 0;
	z-index: 10;
	margin: 0;
	padding: 0;
	background: transparent;
	background-color: transparent;
	background-repeat: no-repeat;
	&:focus { 
		outline: none;
	}
}

.prevwrap,
.nextwrap {
	width: 64px;
	transition: all .5s;
}

.no-touch {
	.prevwrap,
	.nextwrap {
		&:hover { transform: translateX(0px); }
	}
}

.prevwrap {
	transform: translateX(-32px);
	background-position: center right; 
	background-image: url(/dist/img/slider-bg.svg);

	.slick-prev {
		float: right;
		margin-left: 1px;
		margin-right: 7px;
		background-position: center right; 
		background-image: url(/dist/img/slider-arrow-left.svg);
	}

}

.nextwrap  {
	transform: translateX(32px);
	right: 0;
	background-position: center left;
	background-image: url(/dist/img/slider-bg.svg);

	.slick-next { 
		margin-right: 1px;
		margin-left: 7px;
		background-position: center right; 
		background-image: url(/dist/img/slider-arrow-right.svg);
	}

}



.slider-referenzen {
    padding: 0;
	.sf-outer {
		transition: all .3s ease-in-out;
		background-blend-mode: normal;
		position: relative;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 100%;

		img {
			opacity: 0 !important;
		}

		&:hover {
			.sf-inner {
				opacity: 1;
			}
		}

		&.hover {
			background-color: #0b1221;
			background-blend-mode: multiply;

			.sf-inner {
				opacity: 1;
			}
		}
	}

	.sf-inner {
		height: 100%;
		width: 100%;
		color: white;
		text-align: center;
		position: absolute;
		top: 0;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		transition: all 1s ease;
		opacity: 0;
		width: 100%;
		text-align: center;
		color: white;
		padding: 15px;

		span {
			width: 100%;

			&.cat-1 {
				&:before {
					font-family: icomoon;
					content: $icon-eickelbaum_icons_rollaeden;
					font-size: 30px;
					margin-bottom: 15px;
					color: #fff;
				}
			}
			&.cat-2 {
				&:before {
					font-family: icomoon;
					content: $icon-eickelbaum_icons_markisen;
					font-size: 30px;
					margin-bottom: 15px;
					color: #fff;
				}
			}
			&.cat-3 {
				&:before {
					font-family: icomoon;
					content: $icon-eickelbaum_icons_sonnenschutz;
					font-size: 30px;
					margin-bottom: 15px;
					color: #fff;
				}
			}
			&.cat-4 {
				&:before {
					font-family: icomoon;
					content: $icon-eickelbaum_icons_tore;
					font-size: 30px;
					margin-bottom: 15px;
					color: #fff;
				}
			}

			&:before {
				background-color: transparent;
				width: 45px;
				content: "";
				opacity: 1;
				height: 51px;
				display: block;
				margin: 0 auto 35px;

				@media(min-width: $screen-xl-min) { margin: 0 auto 50px; }
				@media(min-width: $screen-lg-min) { margin: 0 auto 65px; }
			}
			&.ani {
				&:before {
					transition-delay: 1s; 
					animation: perspect 1s forwards;
					-moz-animation: none;

				}
				&:after {
					width: 36px;
				}
			}
					
			&:after {
				background-color: white;
			    content: "";
			    display: block;
			    height: 1px;
			    margin: 20px auto 0;
			    width: 0;
			    transition: all .7s linear;
			    //transition-delay: 1s;
			}

		}

	}

}

.head-slider {
	position: relative;
	z-index: 1;
	overflow-x: hidden;
    padding: 0;
	.slick-current .slideContent {
		opacity: 1;
		left: 8.333333%;

		@media(max-width: $screen-xs-max) {
			width: 100%;
			left: 0;
		}

		@media(min-width: $screen-sm-min) {
			margin-top: 70px;
		}

		@media(min-width: $screen-xl-min) {
			margin-top: 100px;
		}
	}


	.slideContent {
		position: absolute;
		left: -33.333333%;
		width: 100%;
		top: 6%;

		@media(max-width: $screen-xs-max) {
			background: $bg-blue;
			position: relative;
			color: #fff;
			left: 0 !important;
			top: 0;
			width: 100%;
			padding: 25px;
			margin-top: -25px;
		}
		@media(min-width: $screen-md-min) {
			bottom: 25.099075%;
			width: 33.333333%;			
		}

		@include transition(.5s);
		transition-delay: .3s;
		opacity: 0;
	
		blockquote {
			border: none;
			font-size: inherit;
			margin: 0;
/*			
			p:first-of-type span:before,
			p:first-of-type span:after {
				position: absolute;
				font-size: 9.6875vw;
				line-height: 1;
				color: $grey-quote;
				font-family: $link-font;
				z-index: -1;
			}

			p:first-of-type span:before {
				content: '“';
				left: -8.5%;
				top: -10%;
			}

			p:first-of-type span:after {
				content: '„';
				right: auto;
				bottom: 1.145833vw;
				margin-left: -20px;
			}
*/
			p {
			
				&, & span {
					position: relative;
					z-index: 2;
				}

				&.cite,
				cite {
					display: block;
					font-size: 12px;
					margin-top: 0.78125vw;

					@media(min-width: $screen-md-min) { font-size: 1.171875vw; }
					@media(min-width: $screen-xl-min) { font-size: 1.09375vw; }
					@media(min-width: $screen-lg-min) { font-size: 1.041666vw; }

				}

			}

		}

	}

}


.scroller {
	position: relative;
	width: 100%;
	z-index: 3;
	.scroll-down {
		z-index: 999;
		display: block;
		left: 50%;
		position: absolute;
		height: 60px;
		width: 53px;
		margin-left: -27px;
		bottom: -30px;
		display: none;
		cursor: pointer;

		@media(min-width: $screen-md-min) { height: 80px; width: 73px; margin-left: -37px; bottom: -38px; }
		@media(min-width: $screen-lg-min) { height: 104px; width: 90px; margin-left: -45px; bottom: -52px; }


		a {
			display: block;
			width: 100%;
			height: 100%;
			font-size: 0;
			color: transparent;
			background-image: url(/dist/img/scroll-bg.svg);
		}

	}
}

.shutter_big {
	background-color: white;
	width: 100%;
	height: 17px;
	position: relative;
	bottom: 34px;
	z-index: 1000;
}

.shutter_small {
	background-color: white;
	width: 100%;
	height: 6px;
	position: relative;
	bottom: 64px;
	z-index: 1000;
}

.mobile_head_text {
	background-color: $bg-blue;
	color: white;
	padding: 10px;
	width: 100%;
	
}