/*!---------- 29. IMAGE BLOCKS ----------*/

.image-md{ max-height: 120px; }
.image-small{ max-height: 80px; }
.image-xs{ max-height: 50px; }
.image-xxs{ max-height: 30px; }
.fade-half{ opacity: 0.5; }
.fade-1-4{ opacity: 0.75; }
.fade-3-4{ opacity: 0.25; }
.fade-on-hover{ @include transition(0.3s); opacity: .5; }
.fade-on-hover:hover{ opacity: 1; }
.cast-shadow{ -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2); -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2); box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2); }
.cast-shadow-light{ -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2); -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2); box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2); }

.image-block, .image-block .image-bg{ padding: 0; height: 50vw; max-height: 700px; }
.image-block .container{ height: 100%; }
.image-block-right .image-bg{ position: absolute; top: 0; }

@media all and (max-width: $screen-sm-min){
	.image-block{ height: auto; max-height: 1000000px; }
	.image-block .image-bg{ height: 350px; }
	.image-block .v-align-transform{ top: 0; transform: none; -webkit-transform: none; }
	.image-block-right .image-bg{ position: relative; }
}

.image-edge {
	padding: 0;
	min-height: 1px;

	img {
		width: 100%;
		height: auto;
	}

	.p0 img {
		float: left;
	}

	.content {
		p {
			margin: 0;
		}

		@media (max-width: $screen-sm-max) { padding: 25px 15px; }
		@media (min-width: $screen-md-min) { padding: 50px 0; }

	}

}

.img-sw img,
.image-edge .p0 img,
.news-img img {
	float: left;
}



.image-edge .container{ position: relative; }
.image-edge .container div[class*='col-']{ position: absolute; }


.image-edge figure {
	float: left;
	transition: all .3s ease-in-out;
	background-blend-mode: normal;
	position: relative;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 100%;
	a {
		display: block;
		float: left;
	}
	&:hover {
			
	}
	img { opacity: 0; }
} 

@media all and (max-width: $screen-sm-max){
	.image-edge .container div[class*='col-']{ position: relative; top: 0; transform: none; -webkit-transform: none; }
}

.image-square{ height: 50vw; max-height: 640px; overflow: hidden; padding: 0;  }
.image-square .image, .image-square > .content{ position: absolute; }
.image-square .image{ height: 100%; }
.image-square > .content{ padding: 0 10%; top: 50%; @include translateY(-50%); }
.image-square.left .image{ left: 0; }
.image-square.left > .content{ right: 0; }

.image-square.right .image{ right: 0; }
.image-square.right > .content{ left: 0; }

@media all and (max-width: $screen-sm-max){
	.image-square{ height: auto; max-height: 100000px; padding-bottom: 48px; }
	.image-square .image, .image-square > .content{ position: relative; }
	.image-square .image{ height: 100vw;; margin-bottom: 48px; max-height: 500px; }
	.image-square > .content{ top: 0; transform: none; -webkit-transform: none; padding: 0 20%; }
}

@media all and (max-width: $screen-xs-max){
	.image-square > .content{ padding: 0 15px; }
}

.image-zoom img{ @include transition(9s); }
.image-zoom:hover img{ @include scale(1.1); }

.image-edge figure img {
	width: 100% !important;
	height: auto !important;
}

.image-caption { position: relative; overflow: hidden; }
.image-caption .caption{ position: absolute; width: 100%; bottom: 0; padding: 16px; color: #fff; }
.image-caption .caption p{ position: relative; z-index: 4; margin: 0; }
.image-caption .caption:before{
	content: ''attr(data-caption)'';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	opacity: 1;
	background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(34,34,34,0.7) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(34,34,34,0.7))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(34,34,34,0.7) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(34,34,34,0.7) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(34,34,34,0.7) 100%); /* IE10+ */
	background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(34,34,34,0.7) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#e6222222',GradientType=0 ); /* IE6-9 */
}
.image-caption.hover-caption .caption{ opacity: 0; @include transition(0.3s); @include translateY(100px); }
.image-caption.hover-caption:hover .caption{ opacity: 1; @include translateY(0); }

.image-edge figure {
	a .mask {
		opacity: 0;
		position: absolute;
		background: transparent url(/dist/img/search.svg) no-repeat center center;
		background-size: 10%;
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		@include transition(0.3s);
	}
	&:hover a .mask {
		opacity: 0.25;
	}
}