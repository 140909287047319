/*!---------- 5. SECTIONS ----------*/

section, footer { position: relative; overflow: hidden; }
.fullscreen.image-bg { height: 50vh; }
.fullscreen.image-bg.vid-bg { height: 90vh; }
.overlay:before{ @include overlay-params(.3,$bg-blue); }
.overlay-heavy:before{ opacity: .6; }
.bg-light.overlay:before{ @include overlay-params(.1,#fff); }

.image-bg { @include heading-colors(#fff); @include text-colors(#fff); }
.image-bg .container, .image-bg div[class*='col-']{ position: relative; z-index: 3; }
.container.image-bg .row{ position: relative; z-index: 3; }

.background-image-holder{ position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 1; background: $bg-blue; background-size: cover !important; background-position: 50% 50% !important; @include transition(0.3s); opacity: 0; }
.background-image-holder img{ display: none; }
.background-multiply .background-image-holder{ background-color: $color-primary !important; background-blend-mode: multiply; }
.background-image-holder.fadeIn{ opacity: 1; }
.parallax > .background-image-holder, .parallax .slides li > .background-image-holder{ height: 100vh; top: -50vh; transition: all 0s ease !important; transition: opacity 0.3s ease !important; -webkit-transform-style: preserve-3d; }
.parallax:first-child .slides li > .background-image-holder, .parallax:first-child .background-image-holder{ top: 0; }
.main-container > a:first-child + .parallax .background-image-holder{ top: 0; }

.fullscreen.image-bg {
	.container,
	.row {
		height: 100%;
	}

	.row {
		width: 100%;
		margin: 0;
	}

	.text-center {
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;
	    height: 100%;
	    -webkit-box-align: center; 
	    -webkit-align-items: center; 
	    -ms-flex-align: center; 
	    align-items: center;
	    .parallax-inner {
	        margin-top: auto;
	        margin-bottom: auto;
	        width: 100%;
	    }
	    p {
	    	margin: 0;
	    	width: 100%;
	    }
	}

}

hr {
	border-top: 1px solid $blue;
	margin: 10px auto 20px;
	width: 150px;
}

@media all and (max-width: $screen-xs-max){
	.parallax > .background-image-holder, .parallax .slides li > .background-image-holder{ top: 0 !important; transform: none !important; -webkit-transform: none !important; }
}

section {
	z-index: 3;
	padding: 25px 0;
	@media (min-width: $screen-sm-min) { padding: 27px 0; }
	@media (min-width: $screen-md-min) { padding: 37px 0; }
	@media (min-width: $screen-xl-min) { padding: 40px 0; }
	@media (min-width: $screen-lg-min) { padding: 50px 0; }
}


.sliderVariableWidth + .accordionWrap,
.sliderVariableWidth + .textseite,
.head-slider + .accordionWrap,
.head-slider + .textseite,
.parallax + .textseite,
.link-banner + .textseite,
.image-edge + .textseite {
	padding-top: 50px;
	@media (min-width: $screen-sm-min) { padding-top: 54px; }
	@media (min-width: $screen-md-min) { padding-top: 74px; }
	@media (min-width: $screen-xl-min) { padding-top: 80px; }
	@media (min-width: $screen-lg-min) { padding-top: 100px; }
}

.accordionWrap + .link-banner,
.accordionWrap + .parallax,
.accordionWrap + .image-edge,
.textseite + .link-banner,
.textseite + .parallax,
.textseite + .image-edge {
	margin-top: 25px;
	@media (min-width: $screen-sm-min) { margin-top: 27px; }
	@media (min-width: $screen-md-min) { margin-top: 37px; }
	@media (min-width: $screen-xl-min) { margin-top: 40px; }
	@media (min-width: $screen-lg-min) { margin-top: 50px; }
}

.textseite img {
	margin-bottom: 16px;
}

.link-banner + section,
.link-banner + div {
	clear: both;
}

.link-banner {
	color: #fff;
	height: 60px; line-height: 60px;
	a, a:hover, a:focus {
		color: #fff;
	}
	@media(min-width: $screen-sm-min) { height: 75px; line-height: 75px; }
	@media(min-width: $screen-md-min) { height: 100px; line-height: 100px; }
	@media(min-width: $screen-xl-min) { height: 120px; line-height: 120px; }
	@media(min-width: $screen-lg-min) { height: 175px; line-height: 175px; }
}

.downloads {
	
	.textseite {
		padding-bottom: 25px;
		@media (min-width: $screen-sm-min) { padding-bottom: 27px; }
		@media (min-width: $screen-md-min) { padding-bottom: 37px; }
		@media (min-width: $screen-xl-min) { padding-bottom: 40px; }
		@media (min-width: $screen-lg-min) { padding-bottom: 50px; }
	}

	.downloadWrap {
		display: flex;
		flex-wrap: wrap;

		.border {
			float: left;
			width: 100%;
			border-top: 1px solid $turq;
			margin: 20px 0 15px;
			@media (min-width: $screen-sm-min) { margin: 30px 0 25px 0; }
			@media (min-width: $screen-md-min) { margin: 60px 0 55px 0; }
			@media (min-width: $screen-lg-min) { margin: 65px 0 60px 0; }
		}
		.downloadText {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			p { width: 100%; }

			@media (min-width: $screen-sm-min) { min-height: 70px; }

			.downloadButtons {
				margin-top: 20px;
				align-self: flex-end;

				a {
					height: 46px; 
					line-height: 46px;
					padding-left: 56px;
					background-image: url(/dist/img/downloads.png);
					background-repeat: no-repeat;
					background-size: auto 38px;
					background-position: 4px 4px;
					@media (min-width: $screen-sm-min) { height: 56px; line-height: 56px; padding-left: 66px; background-size: auto 48px; min-width: 230px; font-size: 13px; }
					@media (min-width: $screen-md-min) { font-size: 15px; }
					@media (min-width: $screen-xl-min) { height: 70px; line-height: 70px; padding-left: 86px; background-size: auto 62px; min-width: 300px; font-size: 18px; }
					@media (min-width: $screen-lg-min) { font-size: 22px; }
				}
			}

		}
		.downloadImage {
			float: left;
			width: 12.4999%;
			margin-left: 4.166665%;
		}
	}
}

.team {

	.textseite {
		padding-bottom: 25px;
		@media (min-width: $screen-sm-min) { padding-bottom: 27px; }
		@media (min-width: $screen-md-min) { padding-bottom: 37px; }
		@media (min-width: $screen-xl-min) { padding-bottom: 40px; }
		@media (min-width: $screen-lg-min) { padding-bottom: 50px; }
	}

	.teamWrap {
		padding-bottom: 40px; 
		@media (min-width: $screen-sm-min) { padding-bottom: 60px; }
		@media (min-width: $screen-md-min) { padding-bottom: 80px; }
		@media (min-width: $screen-lg-min) { padding-bottom: 85px; }

		.teamTxt {
			line-height: 1.3;
			padding-top: 30px;
			@media (min-width: $screen-xl-min) { padding-top: 40px; }
			@media (min-width: $screen-lg-min) { padding-top: 50px; }
		
			table tr td {
				@media (min-width: $screen-sm-min) {
					padding-top: 5px;
					padding-bottom: 5px;
				}
				&:first-of-type { width: 85px; }
			}

		}

		&.colFull {
			@media (min-width: $screen-sm-min) {
				.teamTxt { padding-top: 0 !important; }
			}
		}

	}

}


.textseite {

	[class^="row template-"], 
	[class*=" row tempalte-"] {
		margin-bottom: 35px;
		@media (min-width: $screen-md-min) { margin-bottom: 40px; }
		@media (min-width: $screen-md-min) { margin-bottom: 55px; }
		@media (min-width: $screen-xl-min) { margin-bottom: 65px; }
		@media (min-width: $screen-lg-min) { margin-bottom: 60px; }
	}

}
.immoScoutFrame {
	position: relative; 
	padding-bottom: 125%;
	height: 0; 
	overflow: hidden; 
	width: 100%;
	height: auto;

	iframe {
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; 
		overflow-y: hidden;
	}

}

@media (min-width: $screen-sm-min) { 
	.effect {
		opacity: 0;
		&.animated {
			opacity: 1;
		}
	}
}