.multiply {

	.image-edge figure:hover,
	.slider-referenzen .sf-outer:hover {
		background-color: rgba(32,114,45,1);
		background-blend-mode: multiply;

	}
	.image-edge figure:hover {
		background-size: 110%;
	}

}



.no-multiply {

	.image-edge figure:hover a, 
	.slider-referenzen .sf-outer:hover {
		background-color: rgba(3, 18, 49, 0.85);
	}


}