.banner {

	&.width-icons {
		text-align: center;
		padding-top: 25px;

		@media(min-width: $screen-md-min) { padding-top: 35px; padding-bottom: 40px; }
		@media(min-width: $screen-xl-min) { padding-bottom: 50px; }
		@media(min-width: $screen-lg-min) { padding-top: 55px; padding-bottom: 75px; }


		> div a[class^="icon-"],
		> div a[class*=" icon-"] {
			&:before, & span:before {
				height: 60px;
				width: 53px;

				@media(min-width: $screen-xl-min) { height: 70px; width: 63px; }
				@media(min-width: $screen-lg-min) { height: 100px; width: 89px; }
			}
		}

		> div a[class^="icon-"],
		> div a[class*=" icon-"] {
			display: block;
			line-height: 1;
			width: 100%;

			@media(max-width: $screen-iphone-4-l) { 
				margin-bottom: 25px;
				font-size: 10px;
			}

			@media(min-width: $screen-iphone-4-l) { 
				font-size: 15px;
			}

			@media(min-width: $screen-md-min) { 
				font-size: 20px;
			}

			@media(min-width: $screen-xl-min) { 
				font-size: 25px;
			}
			

			span {
				font-family: $content-font;
				font-size: 15px;
				line-height: 1.4;

				@media(min-width: $screen-xl-min) { font-size: 1.40625vw; }
				@media(min-width: $screen-lg-min) { font-size: 1.302083vw; }
				@media(min-width: 1920px) { font-size: 25px; }

				&:before {
					font-family: $icon-font;
					color: #777777;
					background-color: transparent;
					background-repeat: no-repeat;
					content: '';
					opacity: 1;
					display: block;
					margin: 0 auto 18px;
					transition: .5s all;

					@media(min-width: $screen-md-min) { margin: 0 auto 26px; }
					@media(min-width: $screen-lg-min) { margin: 0 auto 48px; }
				}
			}

			&:after {
				background-color: #777777;
			    content: '';
			    display: block;
			    height: 1px;
			    margin: 28px auto 0;
			    width: 0;
			    transition: all .3s linear;

				@media(min-width: $screen-md-min) { margin: 18px auto 0; }
				@media(min-width: $screen-lg-min) { margin: 28px auto 0; }
			}

			&:hover {
				/*span:before {
					animation: rotate .4s linear;
					animation-iteration-count: 1;
				}

				@keyframes rotate {
    				from{transform:rotate(0deg);}
    				to{transform:rotate(60deg);}
				}*/

				&:after {
					width: 40px;
				}
			}

			&:before {
				position: absolute;
				left: 50%;
				top: 0;
				line-height: 60px;
				margin-left: -27px;

				@media(min-width: $screen-xl-min) { line-height: 70px; margin-left: -32px; }
				@media(min-width: $screen-lg-min) { line-height: 100px; margin-left: -45px; }

			}

		}

	}

	.categorie_icons, a:hover{
		color: #777777;

		&:before {
			font-size: 2.3em;
		}
	}

}