footer {

	p, li, a {
		font-size: 12px;
		line-height: 1.785714;

		@media(min-width: $screen-md-min) { 
			font-size: 14px;
		}

	}

	.footer-block {
		@media (max-width: $screen-iphone-4-l){
			margin-bottom: 40px;
			width: 100%;
		}
	}

	.bg-blue {
		padding-bottom: 50px;
		@media (min-width: $screen-sm-min) { padding-bottom: 80px; }
		@media (min-width: $screen-md-min) { padding-bottom: 120px;  }
		@media (min-width: $screen-lg-min) { padding-bottom: 160px;  }

		.logo-wrap {
			border-bottom: 1px solid $white;
			margin-top: 35px;
			margin-bottom: 15px; padding-bottom: 30px;
			@media (min-width: $screen-sm-min) { margin-top: 50px; }
			@media (min-width: $screen-md-min) { margin-top: 65px; }
			@media (min-width: $screen-lg-min) { margin-top: 110px; margin-bottom: 35px; padding-bottom: 40px; }
		}

		.border-foo {
			border-bottom: 1px solid $white;
			padding-bottom: 5px;
			margin-bottom: 15px;
		}

	}

	.fooNav {
		li {
			a {
				border-bottom: 1px solid $white;
				display: block;
				padding-bottom: 5px;
				padding-top: 15px;
				
				&:after {
					content: ' »';
					margin-left: 0;
					@include transition(0.3s);
					margin-right: 25px;
					float: right;
				}
				&:hover:after {
					margin-left: 10px;
				}
			}
			&:first-of-type a {
				padding-top: 0;
			}

		}

	}

	.bg-dark {
		font-style: italic;
		padding-top: 26px;
		padding-bottom: 26px;

		@media (min-width: $screen-md-min) {
			padding-top: 33px;
			padding-bottom: 33px;
		}
		@media (min-width: $screen-xl-min) {
			padding-top: 40px;
			padding-bottom: 40px;
		}
		@media (min-width: $screen-lg-min) {
			padding-top: 68px;
			padding-bottom: 68px;
		}
	}
	
}

.scrollup {
	color: transparent;
	display: none;
	text-align: center;
	font-size: 0;
	position: fixed;
	z-index: 9999999999999;
	right: 15px;
	bottom: 15px;
	margin-top: -31px;
	.smooth.icon-scroll-top {
		display: block;
		width: 51px;
		height: 62px;
		background-color: transition;
		background-image: url(../img/arrow-top.svg);
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: 100%;
		z-index: 9;
		cursor: pointer;
	}
}

#cookieCon {
	background-color: $blue;
	color: $white;
	font-size: 14px;
	padding: 15px;
	position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 99999999;
    text-align: center;

	div {
		margin: 0 auto;
		width: 100%;
		@media(min-width: $screen-lg-min) { width: 65.66667%; }
		@media(min-width: $screen-xl-min) { width: 83.33333333333334%; }
		
		a {
			color: $white;
			font-size: 14px;
			text-decoration: underline;
		}

		#cookieConCloser {
			border-radius: 16px;
			border: 1px solid white;
			margin-left: 5px;
			padding: 2px 7px;
		}

	}

}