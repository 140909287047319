/* roboto-100 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 100;
	src: url('/dist/fonts/roboto/roboto-v32-latin-100.eot'); /* IE9 Compat Modes */
	src: url('/dist/fonts/roboto/roboto-v32-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/dist/fonts/roboto/roboto-v32-latin-100.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-100.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-100.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src: url('/dist/fonts/roboto/roboto-v32-latin-300.eot'); /* IE9 Compat Modes */
	src: url('/dist/fonts/roboto/roboto-v32-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/dist/fonts/roboto/roboto-v32-latin-300.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-300.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-300.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300italic - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 300;
	src: url('/dist/fonts/roboto/roboto-v32-latin-300italic.eot'); /* IE9 Compat Modes */
	src: url('/dist/fonts/roboto/roboto-v32-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/dist/fonts/roboto/roboto-v32-latin-300italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-300italic.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-300italic.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-300italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: url('/dist/fonts/roboto/roboto-v32-latin-regular.eot'); /* IE9 Compat Modes */
	src: url('/dist/fonts/roboto/roboto-v32-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/dist/fonts/roboto/roboto-v32-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-regular.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-regular.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: url('/dist/fonts/roboto/roboto-v32-latin-500.eot'); /* IE9 Compat Modes */
	src: url('/dist/fonts/roboto/roboto-v32-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/dist/fonts/roboto/roboto-v32-latin-500.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-500.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-500.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500italic - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 500;
	src: url('/dist/fonts/roboto/roboto-v32-latin-500italic.eot'); /* IE9 Compat Modes */
	src: url('/dist/fonts/roboto/roboto-v32-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/dist/fonts/roboto/roboto-v32-latin-500italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-500italic.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-500italic.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-500italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: url('/dist/fonts/roboto/roboto-v32-latin-700.eot'); /* IE9 Compat Modes */
	src: url('/dist/fonts/roboto/roboto-v32-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/dist/fonts/roboto/roboto-v32-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-700.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-700.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 900;
	src: url('/dist/fonts/roboto/roboto-v32-latin-900.eot'); /* IE9 Compat Modes */
	src: url('/dist/fonts/roboto/roboto-v32-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('/dist/fonts/roboto/roboto-v32-latin-900.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-900.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-900.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
		 url('/dist/fonts/roboto/roboto-v32-latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}

body {
	font-weight: 300;
	line-height: 1.5;
	max-width: 1920px;
	margin: 0 auto;
}

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6,p,ul,ol,span,pre,table,blockquote,input,select,textarea { margin-bottom: 0; margin-top: 0; padding: 0; font-weight: 300; }
h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 { font-size: inherit; line-height: inherit; }

a { color: $blue; @include transition(0.3s); cursor: poitner; }
a, a:visited, a:focus, a:active, a:hover { text-decoration: none; outline: none; }

a:focus, a:hover { color: inherit; }

.text-left { text-align: left !important; }
.text-right { text-align: right !important; }
.text-center { text-align: center !important; }

.upper,
.uppercase { text-transform: uppercase; }

.upper-bold { text-transform: uppercase; font-weight: 500; }

// b, strong, .bold { font-weight: 300; }
b, strong, .bold { font-weight: 500; }

.accordion,
.textseite {
	ul {
		margin-left: 20px;
		li {
			position: relative;
			padding-left: 2px;
			margin-bottom: 10px;
			@media (min-width: $screen-sm-min) { margin-bottom: 15px; }
			@media (min-width: $screen-md-min) { padding-left: 15px; }
			@media (min-width: $screen-xl-min) { padding-left: 20px; margin-bottom: 20px; }
			@media (min-width: $screen-lg-min) {  }

			&:before {
				content: '• ';
				color: $blue;
				position: absolute;
				left: -20px;
				font-size: 28px;
				line-height: 22px;
				@media (min-width: $screen-sm-min) { line-height: 23px; }
				@media (min-width: $screen-md-min) {  }
				@media (min-width: $screen-xl-min) { line-height: 26px; font-size: 35px; }
				@media (min-width: $screen-lg-min) { line-height: 28px; }
			}

		}
	}
}

a {
	color: #2c9b3e;
}

a.link-line {
	font-family: $link-font;
	font-size: 15px;
	line-height: 1.466666;
	color: #2c9b3e;

	@media(min-width: $screen-md-min) { font-size: 1.5625vw; }
	@media(min-width: $screen-xl-min) { font-size: 1.328125vw; }
	@media(min-width: $screen-lg-min) { font-size: 1.041666vw; }
	@media(min-width: 1920px) { font-size: 20px; }


	@media(min-width: $screen-md-min) { 
		&:after {
			background-color: $blue;
			content: '';
			display: block;
			height: 1px;
			margin-top: 30px;
			width: 0;
			transition: all .3s linear;
		}

		&:hover {
			&:before {
				transition-delay: 1s; 
				animation: perspect 1s forwards;
			}
			&:after {
				width: 36px;
			}
		}
	}
}

.text-center a.link-line:after {
	margin: 35px auto 0;
}

body, p {
	font-size: 13px;
	line-height: 1.538461;

	@media(min-width: $screen-md-min) { font-size: 1.367187vw; line-height: 1.571428; }
	@media(min-width: $screen-xl-min) { font-size: 1.171875vw; line-height: 1.866666; }
	@media(min-width: $screen-lg-min) { font-size: 0.9375vw; line-height: 1.944444; }
	@media(min-width: 1920px) { font-size: 18px; }
}

.serif-45,
.headline-35,
.headline-25,
.headline-20,
.size-15 {
	display: block;
}

.serif-20,
.serif-45 {
	font-family: $link-font;
}

.serif-45 {
	font-size: 18px;
	line-height: 1.333333;
	text-shadow: 4px 4px 4px #cecece;
	
	@media(min-width: $screen-md-min) { font-size: 2.539062vw; line-height: 1.269230; }
	@media(min-width: $screen-xl-min) { font-size: 2.34375vw; line-height: 1.466666; }
	@media(min-width: $screen-lg-min) { font-size: 2.343475vw; line-height: 1.444444; }
	@media(min-width: 1920px) { font-size: 45px; }
}

.headline-35 {
	font-size: 20px;
	line-height: 1.5;

	@media(min-width: $screen-md-min) { font-size: 2.148437vw; line-height: 1.545454; }
	@media(min-width: $screen-xl-min) { font-size: 2.03125vw; line-height: 1.461538; }
	@media(min-width: $screen-lg-min) { font-size: 1.822916vw; line-height: 1.428571; }
	@media(min-width: 1920px) { font-size: 35px; }
}

.headline-25 {
	font-size: 15px;
	line-height: 1.4;

	@media(min-width: $screen-xl-min) { font-size: 1.40625vw; }
	@media(min-width: $screen-lg-min) { font-size: 1.302083vw; }
	@media(min-width: 1920px) { font-size: 25px; }
}

.serif-20,
.headline-20 {
	font-size: 15px;
	line-height: 1.333333;
	color: $color-primary;

	@media(min-width: $screen-md-min) { font-size: 1.5625vw; line-height: 1.571428; }
	@media(min-width: $screen-xl-min) { font-size: 1.328125vw; line-height: 1.647058; }
	@media(min-width: $screen-lg-min) { font-size: 1.041666vw; line-height: 1.428571; }
	@media(min-width: 1920px) { font-size: 20px; }
}

.headline-20 { font-style: italic; }


small,
.size-15 {
	font-size: 13px;
	line-height: 1.428571;

	@media(min-width: $screen-md-min) { font-size: 14px; line-height: 1.5; }
	@media(min-width: $screen-xl-min) { font-size: 13px; line-height: 1.615384; }
	@media(min-width: $screen-lg-min) { font-size: 15px; line-height: 2; }
}

.footer_headline {
	display: block;
	position: relative;

	&:after {
		content: '';
		border-bottom: 1px solid white;
		position: absolute;
		bottom: -6px;
		left: 0;
		width: 100%;
	}
}