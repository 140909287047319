.bg-primary {
	background: $bg-primary;
	color: $color-primary;
}

.bg-primary p {
	color: #777777;
}

.bg-dark {
	background-color: $bg-dark;
}

.bg-blue {
	background-color: $bg-blue;
}

.bg-pilgram-grey {
	background-color: $bg-pilgram-grey;
}

.bg-dark,
.bg-blue {
	&, & a {
		color: $white;
	}

}

.color-blue {
	color: $blue;
}
.Ebene_1 {
	fill: white;
}